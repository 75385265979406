import * as React from "react"
import Layout from "../components/Layout"

const NotFoundPage = () => {
  
  return (
    <Layout>
      <div className="container">
        <div className="page-not-found">
            <h2 className="mb-0 text-center pb-104 pt-104">Page not found</h2>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
